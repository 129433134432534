"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !exports.hasOwnProperty(p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialState = exports.fetchAssets = exports.setListOfAssetIds = exports.setAssetsById = exports.setFilesByAssetId = exports.saveAssetChanges = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
exports.saveAssetChanges = toolkit_1.createAction('saveAssetChanges');
exports.setFilesByAssetId = toolkit_1.createAction('setFilesByAssetId');
exports.setAssetsById = toolkit_1.createAction('setAssetsById');
exports.setListOfAssetIds = toolkit_1.createAction('setListOfAssetIds');
exports.fetchAssets = toolkit_1.createAction('fetchAssets');
exports.initialState = {
    assetsById: {},
    listOfAssetIds: [],
    filesByAssetId: {}
};
const reducer = toolkit_1.createReducer(exports.initialState, {
    [exports.setAssetsById.type]: (state, { payload: { assetsById } }) => {
        state.assetsById = assetsById;
    },
    [exports.setListOfAssetIds.type]: (state, { payload: { listOfAssetIds } }) => {
        state.listOfAssetIds = listOfAssetIds;
    },
    [exports.setFilesByAssetId.type]: (state, { payload: { uuid, files } }) => {
        state.filesByAssetId[uuid] = files;
    }
});
__exportStar(require("./selectors"), exports);
exports.default = reducer;
