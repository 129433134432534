"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.List = void 0;
const react_1 = __importStar(require("react"));
const react_redux_1 = require("react-redux");
const toolkit_1 = require("@reduxjs/toolkit");
const icons_1 = require("bdx-af-ui/icons");
const config_1 = require("../../../store/ducks/config");
const assets_1 = require("../../../store/ducks/assets");
const core_1 = require("bdx-af-ui/core");
const apiRequestsStatuses_1 = require("../../../store/ducks/apiRequestsStatuses");
const _1 = require("./");
exports.List = ({ assetsById, listOfAssetIds, fetchAssets, isAppReady, requestStatus }) => {
    react_1.useEffect(() => {
        if (isAppReady) {
            fetchAssets();
        }
    }, [isAppReady]);
    return (react_1.default.createElement(react_1.default.Fragment, null, requestStatus.isPending
        ? react_1.default.createElement(icons_1.Spinner, null)
        : listOfAssetIds.length > 0
            ? listOfAssetIds.map((id, index) => (react_1.default.createElement(_1.Item, { key: `deal_asset_${id}`, title: assetsById[id].title, uuid: id, index: (index + 1) })))
            : react_1.default.createElement(core_1.Typography, { "data-testid": 'no-assets-text' }, "No assets attached to this deal.")));
};
const mapStateToProps = (state) => ({
    isAppReady: config_1.getIsAppReady(state),
    assetsById: assets_1.getAssetsById(state),
    listOfAssetIds: assets_1.getListOfAssetIds(state),
    requestStatus: apiRequestsStatuses_1.getRequestStatus(assets_1.fetchAssets.type)(state)
});
const mapDispatchToProps = (dispatch) => toolkit_1.bindActionCreators({
    fetchAssets: assets_1.fetchAssets
}, dispatch);
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(exports.List);
