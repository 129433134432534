"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !exports.hasOwnProperty(p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialState = exports.updateDeals = exports.setPaginationParams = exports.setSortByParams = exports.setListOfDealIds = exports.setDealsById = exports.createDeal = exports.fetchDeals = exports.initDealsDetails = exports.initDeals = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
exports.initDeals = toolkit_1.createAction('initDeals');
exports.initDealsDetails = toolkit_1.createAction('initDealsDetails');
exports.fetchDeals = toolkit_1.createAction('fetchDeals');
exports.createDeal = toolkit_1.createAction('createDeal');
exports.setDealsById = toolkit_1.createAction('setDealsById');
exports.setListOfDealIds = toolkit_1.createAction('setListOfDealIds');
exports.setSortByParams = toolkit_1.createAction('setSortByParams');
exports.setPaginationParams = toolkit_1.createAction('setPaginationParams');
exports.updateDeals = toolkit_1.createAction('updateDeals');
exports.initialState = {
    dealsById: {},
    listOfDealIds: [],
    paginationParams: {
        first: false,
        last: false,
        number: 0,
        numberOfElements: 0,
        range: '',
        size: 0,
        totalElements: 0,
        totalPages: 0
    },
    sortByParams: {
        direction: 'desc',
        property: 'item.updatedDate'
    }
};
const reducer = toolkit_1.createReducer(exports.initialState, {
    [exports.setPaginationParams.type]: (state, { payload: { paginationParams } }) => {
        state.paginationParams = paginationParams;
    },
    [exports.setSortByParams.type]: (state, { payload: { sortByParams } }) => {
        state.sortByParams = sortByParams;
    },
    [exports.setDealsById.type]: (state, { payload: { dealsById } }) => {
        state.dealsById = dealsById;
    },
    [exports.setListOfDealIds.type]: (state, { payload: { listOfDealIds } }) => {
        state.listOfDealIds = listOfDealIds;
    }
});
__exportStar(require("./selectors"), exports);
exports.default = reducer;
