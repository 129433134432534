"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !exports.hasOwnProperty(p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialState = exports.setShowArchived = exports.setUrlParams = exports.setUrlParam = exports.clearFilters = exports.setFilters = exports.initFilters = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
exports.initFilters = toolkit_1.createAction('initFilters');
exports.setFilters = toolkit_1.createAction('setFilters');
exports.clearFilters = toolkit_1.createAction('clearFilters');
exports.setUrlParam = toolkit_1.createAction('setUrlParam');
exports.setUrlParams = toolkit_1.createAction('setUrlParams');
exports.setShowArchived = toolkit_1.createAction('setShowArchived');
exports.initialState = {
    urlParams: {
        page: 0,
        orderProperty: 'item.updatedDate',
        orderDirection: 'desc',
        ownerId: '',
        groupId: '',
        stage: '',
        type: '',
        status: 'ACTIVE',
        size: 12
    },
    filters: {
        list: [
            {
                label: 'Type',
                property: 'types',
                sort: false,
                param: 'type',
                selected: '',
                items: []
            },
            {
                label: 'Owner',
                property: 'owners',
                param: 'ownerId',
                sort: true,
                selected: '',
                items: []
            },
            {
                label: 'Stage',
                property: 'stages',
                param: 'stage',
                sort: false,
                selected: '',
                items: []
            },
            {
                label: 'Customer',
                property: 'customers',
                param: 'groupId',
                sort: true,
                selected: '',
                items: []
            }
        ]
    }
};
const reducer = toolkit_1.createReducer(exports.initialState, {
    [exports.setFilters.type]: (state, { payload: { filters } }) => {
        state.filters = filters;
    },
    [exports.setUrlParams.type]: (state, { payload: { urlParams } }) => {
        state.urlParams = urlParams;
    }
});
__exportStar(require("./selectors"), exports);
exports.default = reducer;
