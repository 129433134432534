"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.saveAssetChangesSaga = exports.createFileObject = exports.fetchAndSetFilesAssignedToAssetSaga = exports.initAssetsSaga = void 0;
const effects_1 = require("redux-saga/effects");
const _1 = require("./");
const config_1 = require("../config");
const apiRequestsStatuses_1 = require("../apiRequestsStatuses/");
const sagas_1 = require("../apiRequestsStatuses/sagas");
const assets_1 = require("../../../api/assets");
const selectors_1 = require("./selectors");
function* initAssetsSaga() {
    const activeGroupId = yield effects_1.select(config_1.getActiveGroupId);
    const dealId = yield effects_1.select(config_1.getDealId);
    if (activeGroupId !== null && dealId !== null) {
        const dealAssets = yield sagas_1.handleAsyncCall(_1.fetchAssets.type, assets_1.getAssets, dealId, activeGroupId);
        const assetsById = {};
        const listOfAssetIds = [];
        dealAssets.content.forEach((asset) => {
            assetsById[asset.uuid] = { title: asset.title, description: asset.description, id: asset.id };
            listOfAssetIds.push(String(asset.uuid));
        });
        listOfAssetIds.sort((a, b) => { var _a; return (((_a = assetsById[a]) === null || _a === void 0 ? void 0 : _a.assetDealOrderIndex) <= assetsById[b].assetDealOrderIndex ? -1 : 1); });
        yield effects_1.all([
            effects_1.put(_1.setAssetsById({ assetsById })),
            effects_1.put(_1.setListOfAssetIds({ listOfAssetIds }))
        ]);
        yield effects_1.call(fetchAndSetFilesAssignedToAssetSaga);
    }
}
exports.initAssetsSaga = initAssetsSaga;
function* fetchAndSetFilesAssignedToAssetSaga() {
    const assetUuids = yield effects_1.select(selectors_1.getListOfAssetIds);
    for (const assetUuid of assetUuids) {
        yield effects_1.put(apiRequestsStatuses_1.setRequestPending({ key: assetUuid, pending: true }));
    }
    const fileBaseUrl = yield effects_1.call(waitForFileBaseurl);
    for (const assetUuid of assetUuids) {
        const getFilesResponse = yield sagas_1.handleAsyncCall(assetUuid, assets_1.getFilesAssignedToAsset, assetUuid);
        const files = getFilesResponse.map(file => exports.createFileObject(file, fileBaseUrl));
        yield effects_1.put(_1.setFilesByAssetId({ uuid: assetUuid, files }));
    }
}
exports.fetchAndSetFilesAssignedToAssetSaga = fetchAndSetFilesAssignedToAssetSaga;
exports.createFileObject = (file, fileBaseUrl) => ({
    id: file.PartitionKey,
    baseUrl: `${fileBaseUrl}/v2/files/${file.PartitionKey}/image`,
    type: file.MimeType,
    title: file.Title,
    groupId: file.CompanyId,
    position: file.Position
});
function* waitForFileBaseurl() {
    let fileBaseUrl = '';
    do {
        fileBaseUrl = yield effects_1.select(config_1.getFileBaseUrl);
        yield effects_1.delay(100);
    } while (!fileBaseUrl);
    return fileBaseUrl;
}
function* saveAssetChangesSaga() {
    yield console.log('saving asset changes');
}
exports.saveAssetChangesSaga = saveAssetChangesSaga;
function* default_1() {
    yield effects_1.takeLatest(_1.fetchAssets.type, initAssetsSaga);
    yield effects_1.takeLatest(_1.saveAssetChanges.type, saveAssetChangesSaga);
}
exports.default = default_1;
