"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatListOfIds = exports.formatDealsById = exports.initDealsDetailsSaga = exports.createDealSaga = exports.updateDealsSaga = exports.getDealsSaga = exports.initDealsSaga = void 0;
const effects_1 = require("redux-saga/effects");
const sagas_1 = require("../apiRequestsStatuses/sagas");
const deals_1 = require("../../../api/deals");
const _1 = require("./");
const filters_1 = require("../filters");
const config_1 = require("../config");
function* initDealsSaga() {
    yield getDealsSaga();
}
exports.initDealsSaga = initDealsSaga;
function* getDealsSaga() {
    const urlParams = yield effects_1.select(filters_1.getUrlParams);
    const deals = yield sagas_1.handleAsyncCall('fetchingDeals', deals_1.getDeals, urlParams);
    const minRange = (deals.number * deals.size + 1);
    const maxRange = (deals.number * deals.size + deals.size);
    const paginationParams = {
        page: urlParams.page,
        first: deals.first,
        last: deals.last,
        number: deals.number,
        numberOfElements: deals.numberOfElements,
        range: `${minRange} - ${maxRange < deals.totalElements ? maxRange : deals.totalElements}`,
        size: deals.size,
        totalElements: deals.totalElements,
        totalPages: deals.totalPages
    };
    yield effects_1.put(_1.setPaginationParams({ paginationParams }));
    const dealsById = exports.formatDealsById(deals.content);
    const listOfDealIds = exports.formatListOfIds(deals.content);
    yield effects_1.put(_1.setDealsById({ dealsById }));
    yield effects_1.put(_1.setListOfDealIds({ listOfDealIds }));
}
exports.getDealsSaga = getDealsSaga;
function* updateDealsSaga(action) {
    const params = yield effects_1.select(filters_1.getUrlParams);
    const urlParams = Object.assign(Object.assign({}, params), ((action === null || action === void 0 ? void 0 : action.payload) && Object.assign({}, action.payload)));
    window.sessionStorage.setItem('dealsListViewParams', JSON.stringify(urlParams));
    yield effects_1.put(filters_1.setUrlParams({ urlParams }));
    yield getDealsSaga();
}
exports.updateDealsSaga = updateDealsSaga;
function* createDealSaga(action) {
    yield sagas_1.handleAsyncCall(_1.createDeal.type, deals_1.createDeal, action.payload);
    yield getDealsSaga();
}
exports.createDealSaga = createDealSaga;
function* initDealsDetailsSaga() {
    const dealId = yield effects_1.select(config_1.getDealId);
    const activeDeal = yield sagas_1.handleAsyncCall('fetchActiveDeal', deals_1.getDealById, dealId);
    const dealsById = {
        [activeDeal.id]: activeDeal
    };
    const listOfDealIds = [activeDeal.id];
    yield effects_1.put(_1.setDealsById({ dealsById }));
    yield effects_1.put(_1.setListOfDealIds({ listOfDealIds }));
}
exports.initDealsDetailsSaga = initDealsDetailsSaga;
exports.formatDealsById = (deals) => deals.reduce((acc, cv) => {
    acc[cv.id] = cv;
    return acc;
}, {});
exports.formatListOfIds = (deals) => deals.map(deal => String(deal.id));
function* default_1() {
    yield effects_1.takeLatest(_1.initDeals.type, initDealsSaga);
    yield effects_1.takeLatest(_1.initDealsDetails.type, initDealsDetailsSaga);
    yield effects_1.takeLatest(_1.updateDeals.type, updateDealsSaga);
    yield effects_1.takeEvery(_1.createDeal.type, createDealSaga);
}
exports.default = default_1;
