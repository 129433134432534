"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initDetailsConfigSaga = exports.initConfigSaga = void 0;
const effects_1 = require("redux-saga/effects");
const _1 = require("./");
const sagas_1 = require("../apiRequestsStatuses/sagas");
const config_1 = require("../../../api/config");
const sagas_2 = require("../../../store/ducks/deals/sagas");
function* initConfigSaga() {
    const paramString = window.location.search.substring(1);
    yield effects_1.put(_1.setUnformattedUrlParams({ paramString }));
    const params = new URLSearchParams(window.location.search.substring(1));
    const hostName = window.location.hostname;
    const isLocal = hostName.split('.')[1] === 'local';
    const isStaging = hostName.split('.')[1] === 'staging';
    yield effects_1.put(_1.setConfigUrlParam({ param: 'local', value: isLocal }));
    if (isLocal || isStaging) {
        yield effects_1.put(_1.setConfigUrlParam({ param: 'branch', value: params.get('branch') }));
        yield effects_1.put(_1.setConfigUrlParam({ param: 'showAssetsTab', value: true }));
    }
}
exports.initConfigSaga = initConfigSaga;
function* initDetailsConfigSaga() {
    yield effects_1.call(initConfigSaga);
    const unformattedParams = yield effects_1.select(_1.getUnformattedUrlParams);
    const params = new URLSearchParams(unformattedParams);
    const dealId = params.get('dealId');
    const activeGroupId = yield sagas_1.handleAsyncCall('getActiveGroupId', config_1.getActiveGroupId);
    yield effects_1.put(_1.setDealId({ dealId }));
    yield effects_1.put(_1.setActiveGroupId({ groupId: String(activeGroupId) }));
    const fileBaseUrl = yield sagas_1.handleAsyncCall('getFileBaseUrl', config_1.getBaseUrl);
    yield effects_1.put(_1.setFileBaseUrl({ fileBaseUrl }));
    yield effects_1.call(sagas_2.initDealsDetailsSaga);
}
exports.initDetailsConfigSaga = initDetailsConfigSaga;
function* default_1() {
    yield effects_1.takeLatest(_1.initDetailsConfig.type, initDetailsConfigSaga);
    yield effects_1.takeLatest(_1.initConfig.type, initConfigSaga);
}
exports.default = default_1;
