"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var KEYS;
(function (KEYS) {
    KEYS["API_REQUESTS_STATUSES"] = "API_REQUESTS_STATUSES";
    KEYS["DEALS"] = "DEALS";
    KEYS["FILTERS"] = "FILTERS";
    KEYS["CONFIG"] = "CONFIG";
    KEYS["ASSETS"] = "ASSETS";
})(KEYS || (KEYS = {}));
exports.default = KEYS;
