"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !exports.hasOwnProperty(p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialState = exports.initDetailsConfig = exports.initConfig = exports.setUnformattedUrlParams = exports.setConfigUrlParam = exports.setActiveGroupId = exports.setDealId = exports.setFileBaseUrl = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
exports.setFileBaseUrl = toolkit_1.createAction('setFileBaseUrl');
exports.setDealId = toolkit_1.createAction('setDealId');
exports.setActiveGroupId = toolkit_1.createAction('setActiveGroupId');
exports.setConfigUrlParam = toolkit_1.createAction('setIsLocal');
exports.setUnformattedUrlParams = toolkit_1.createAction('setUnformattedUrlParams');
exports.initConfig = toolkit_1.createAction('initConfig');
exports.initDetailsConfig = toolkit_1.createAction('initDetailsConfig');
exports.initialState = {
    configUrlParams: {
        local: false,
        branch: null
    },
    activeGroupId: null,
    dealId: null,
    fileBaseUrl: null,
    unformattedUrlParams: null
};
const reducer = toolkit_1.createReducer(exports.initialState, {
    [exports.setConfigUrlParam.type]: (state, { payload: { param, value } }) => {
        state.configUrlParams[param] = value;
    },
    [exports.setActiveGroupId.type]: (state, { payload: { groupId } }) => {
        state.activeGroupId = groupId;
    },
    [exports.setDealId.type]: (state, { payload: { dealId } }) => {
        state.dealId = dealId;
    },
    [exports.setFileBaseUrl.type]: (state, { payload: { fileBaseUrl } }) => {
        state.fileBaseUrl = fileBaseUrl;
    },
    [exports.setUnformattedUrlParams.type]: (state, { payload: { paramString } }) => {
        state.unformattedUrlParams = paramString;
    }
});
__exportStar(require("./selectors"), exports);
exports.default = reducer;
