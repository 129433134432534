"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !exports.hasOwnProperty(p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setRequestError = exports.setRequestPending = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
exports.setRequestPending = toolkit_1.createAction('setRequestPending');
exports.setRequestError = toolkit_1.createAction('setRequestError');
const initialState = {
    pending: {},
    error: {}
};
const reducer = toolkit_1.createReducer(initialState, {
    [exports.setRequestPending.type]: (state, { payload }) => {
        state.pending[payload.key] = payload.pending;
    },
    [exports.setRequestError.type]: (state, { payload }) => {
        state.error[payload.key] = payload.error;
    }
});
__exportStar(require("./selectors"), exports);
exports.default = reducer;
