"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleAsyncCall = void 0;
const effects_1 = require("redux-saga/effects");
const apiRequestsStatuses_1 = require("../apiRequestsStatuses");
function* handleAsyncCall(key, method, ...methodParams) {
    yield effects_1.put(apiRequestsStatuses_1.setRequestPending({ key, pending: true }));
    let res;
    try {
        res = yield effects_1.call(method, ...methodParams);
    }
    catch (e) {
        yield effects_1.put(apiRequestsStatuses_1.setRequestError({ key, error: e.message }));
        yield effects_1.put(apiRequestsStatuses_1.setRequestPending({ key, pending: false }));
        throw e;
    }
    yield effects_1.put(apiRequestsStatuses_1.setRequestPending({ key, pending: false }));
    return res;
}
exports.handleAsyncCall = handleAsyncCall;
