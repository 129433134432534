"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertFilterObjectFromDictionary = exports.getFilterDataSaga = exports.clearFiltersSaga = exports.initFiltersSaga = void 0;
const effects_1 = require("redux-saga/effects");
const sagas_1 = require("../apiRequestsStatuses/sagas");
const filters_1 = require("../../../api/filters");
const _1 = require("./");
const deals_1 = require("../deals");
const sagas_2 = require("../deals/sagas");
function* initFiltersSaga() {
    const savedFilters = window.sessionStorage.getItem('dealsListViewParams');
    if (savedFilters) {
        try {
            yield yield effects_1.put(_1.setUrlParams({ urlParams: JSON.parse(savedFilters) }));
        }
        catch (e) { }
    }
    yield getFilterDataSaga();
}
exports.initFiltersSaga = initFiltersSaga;
function* clearFiltersSaga() {
    const savedFilters = window.sessionStorage.getItem('dealsListViewParams');
    if (savedFilters) {
        try {
            window.sessionStorage.removeItem('dealsListViewParams');
        }
        catch (e) { }
    }
    yield effects_1.put(deals_1.setSortByParams({ sortByParams: deals_1.initialState.sortByParams }));
    yield effects_1.put(_1.setUrlParams({ urlParams: _1.initialState.urlParams }));
    yield sagas_2.getDealsSaga();
}
exports.clearFiltersSaga = clearFiltersSaga;
function* getFilterDataSaga() {
    const filterItems = {};
    const filterDataDics = yield effects_1.all({
        stages: sagas_1.handleAsyncCall(`${_1.initFilters.type}_STAGES`, filters_1.getStages),
        types: sagas_1.handleAsyncCall(`${_1.initFilters.type}_TYPES`, filters_1.getTypes)
    });
    const filterDataArrs = yield effects_1.all({
        owners: sagas_1.handleAsyncCall(`${_1.initFilters.type}_OWNERS`, filters_1.getOwners),
        customers: sagas_1.handleAsyncCall(`${_1.initFilters.type}_CUSTOMERS`, filters_1.getCustomers)
    });
    for (const [key, val] of Object.entries(filterDataDics))
        filterItems[key] = exports.convertFilterObjectFromDictionary(val);
    for (const [key, val] of Object.entries(filterDataArrs))
        filterItems[key] = val.map((filter) => ({ label: filter.name, value: filter.id }));
    const filterList = yield effects_1.select(_1.getFilterList);
    const list = filterList.map(filter => (Object.assign(Object.assign({}, filter), { items: filterItems[filter.property] })));
    yield effects_1.put(_1.setFilters({ filters: { list } }));
}
exports.getFilterDataSaga = getFilterDataSaga;
exports.convertFilterObjectFromDictionary = (obj) => (Object.values(obj.items).map(value => ({
    label: obj.dictionary[value],
    value
})));
function* default_1() {
    yield effects_1.takeLatest(_1.initFilters.type, initFiltersSaga);
    yield effects_1.takeLatest(_1.clearFilters.type, clearFiltersSaga);
}
exports.default = default_1;
